import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import { Cloudinary } from '@cloudinary/url-gen';
import { fill } from '@cloudinary/url-gen/actions/resize';
import { dpr, format } from '@cloudinary/url-gen/actions/delivery';

// Initialize Cloudinary instance
const cld = new Cloudinary({
  cloud: {
    cloudName: 'dl1kjmaoq', // Replace with your Cloudinary cloud name
  },
});

const products = [
  {
    name: 'iPhone',
    image: 'static/tcei37whtrgypxjdjouy', // Image public ID
    description: 'iPhone 8 or later',
    link: '/iphone',
    bgColor: 'bg-pink-100',
    maxPrice: '430,000',
  },
  {
    name: 'Samsung',
    image: 'static/hvijmi7sggt0jbwc2re2', // Image public ID
    description: 'Galaxy S, Note, & More',
    link: '/samsung',
    bgColor: 'bg-indigo-100',
    maxPrice: '260,000',
  },
  {
    name: 'iPad',
    image: 'static/bp4ephneohsdjf1mioix', // Image public ID
    description: 'iPad & Accessories',
    link: '/ipad',
    bgColor: 'bg-yellow-100',
    maxPrice: '230,000',
  },
  {
    name: 'MacBook',
    image: 'static/srmfrevprjsbhryd1eoa', // Image public ID
    description: 'Mac mini to the Pro',
    link: '/macbook',
    bgColor: 'bg-cyan-100',
    maxPrice: '600,000',
  },
  {
    name: 'Watch',
    image: 'static/eiwvrbozilkckwlmneog', // Image public ID
    description: 'Series 3 or later',
    link: '/watch',
    bgColor: 'bg-green-100',
    maxPrice: '100,000',
  },
  {
    name: 'Accessories',
    image: 'static/fzzf0d3rw9ovkgh5ydgh', // Image public ID
    description: 'Chargers, Headphones, & More',
    link: '#',
    bgColor: 'bg-purple-100',
    maxPrice: '50,000',
  },
];

// Helper to preload images using <link> with fetchpriority
const preloadImage = (url) => {
  const link = document.createElement('link');
  link.rel = 'preload';
  link.as = 'image';
  link.href = url;
  link.setAttribute('fetchpriority', 'high'); // Set high priority for LCP
  document.head.appendChild(link);
};

const Home = () => {
  const navigate = useNavigate(); // Initialize navigate hook

  useEffect(() => {
    // Preload product images on component mount
    products.forEach((product) => {
      const productImage = cld.image(product.image)
        .resize(fill().width(256).height(256)) // Resize to 256x256
        .delivery(dpr('auto'))
        .quality('auto')
        .toURL();

      preloadImage(productImage);
    });
  }, []);

  const handleProductClick = (link) => {
    window.scrollTo({ top: 0, behavior: 'smooth' }); // Scroll to top
    navigate(link); // Navigate to the selected product page
  };

  return (
    <div className="container mx-auto py-8 sm:py-24 px-5 lg:px-0">
      {/* Responsive Banner */}
      <div>
        {/* Preload banner image */}
        <link
          rel="preload"
          href={cld.image('static/oafrqhlqgxjcwwja3qjy')
            .resize(fill().width(250).height(100))
            .delivery(dpr('auto')).quality('auto').toURL()}
          as="image"
          fetchpriority="high"
        />

        <div className="bg-lime-100 py-10 px-4 sm:px-6 md:px-10 lg:px-16 rounded-3xl text-center mb-12 hidden sm:block">
          <div className="flex justify-center mt-6">
            <img
              src={cld.image('static/oafrqhlqgxjcwwja3qjy')
                .resize(fill().width(250).height(100))
                .delivery(dpr('auto')).quality('auto').toURL()}
              alt="Trade-in devices"
              className="w-[250px] h-[100px] bg-transparent"
            />
          </div>
          <h1 className="text-3xl sm:text-4xl lg:text-5xl font-urbanist font-bold text-black">
            It pays <span className="text-purple-500">‘more’</span> to trade-in
          </h1>
          <p className="font-urbanist text-lg sm:text-xl lg:text-2xl text-gray-700 mt-4">
            Trade-in your devices for Cash or get up to 10% extra <br /> value when upgrading to a newer model
          </p>
        </div>

        {/* Visible only on phones */}
        <div className="text-center mb-12 sm:hidden">
          <h1 className="text-4xl lg:text-5xl font-semibold font-urbanist mb-2 mt-10">
            Trade-in your devices
          </h1>
          <p className="text-gray-600 text-lg lg:text-xl font-urbanist">
            For Cash or Extra Value Store Credit!
          </p>
        </div>
      </div>

      {/* Products grid */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 mt-12 mb-12 max-w-7xl mx-auto">
        {products.map((product) => {
          const productImage = cld.image(product.image)
            .resize(fill().width(256).height(256)) // Resize to 256x256
            .delivery(format('auto'))
            .delivery(dpr('auto'))
            .quality('auto:low')
            .toURL();

          return (
            <div
              key={product.name}
              onClick={() => handleProductClick(product.link)} // Call the handleProductClick function
              className={`${product.bgColor} p-6 rounded-2xl shadow-lg transition-transform duration-300 hover:scale-105 cursor-pointer`}
            >
              <div className="flex justify-center mb-4">
                <img
                  src={productImage}
                  alt={product.name}
                  className="h-64 object-contain rounded-lg"
                  style={{ filter: 'blur(10px)', transition: 'filter 0.3s' }}
                  onLoad={(e) => (e.target.style.filter = 'none')}
                />
              </div>
              <div className="text-center">
                <h2 className="text-xl font-semibold font-urbanist mb-1">
                  {product.name}
                </h2>
                <p className="text-gray-600 mb-3 font-urbanist">
                  {product.description}
                </p>
                {/* Price section */}
                <div className="bg-white rounded-full py-2 px-4 shadow-md">
                  <p className="text-black font-semibold font-urbanist">
                    Get up-to Rs: {product.maxPrice}
                  </p>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Home;
