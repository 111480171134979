import React, { useState, useEffect, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axiosInstance from './baseURL';
import { CheckCircleIcon } from '@heroicons/react/24/solid'; // Heroicons for icons

const WatchDetails = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [product, setProduct] = useState(null);
  const [selectedOptions, setSelectedOptions] = useState({
    watchCaseType: null,
    watchCaseFinishOption: null,
    watchCaseSize: null,
    bandsType: null,
    repair: [],
    batteryHealth: null,
    strapCondition: null,
    cosmeticIssues: [],
    faults: [],
    otherRepairText: '',
    bodyCondition: null,
    accessories: null,
    paymentOption: null,
    isFaulty: null,
    isDamaged: null,
    isRepaired: null,
  });
    const [finalPrice, setFinalPrice] = useState(0);
  const [instantCashValue, setInstantCashValue] = useState(0);
  const [storeCreditValue, setStoreCreditValue] = useState(0);
  const [isPaymentSelected, setIsPaymentSelected] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [showPopup, setShowPopup] = useState(false);
  const [step, setStep] = useState(1);
  const [customerDetails, setCustomerDetails] = useState({
  fullName: '',
  whatsapp: '',
  isInLahore: '',
  buyingPreference: '',
  address: '',
  ipAddress: '', // Add ipAddress field for storing user's IP address
  location: null, // Add location field for storing geolocation
});

const handleCustomerDetailsChange = (field, value) => {
  setCustomerDetails((prevDetails) => ({
    ...prevDetails,
    [field]: value,
  }));
};

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const calculateFinalPrice = useCallback((options) => {
    let originalMaxPrice = product.maxPrice || 0;
    let adjustedPrice = originalMaxPrice;
    let calculatedPrice = adjustedPrice;
    
    if (options.watchCaseSize && options.watchCaseSize.deductionPercentage) {
        calculatedPrice -= Math.round(adjustedPrice * (options.watchCaseSize.deductionPercentage / 100));
    }
    if (options.watchCaseType && options.watchCaseType.deductionPercentage) {
        calculatedPrice -= Math.round(adjustedPrice * (options.watchCaseType.deductionPercentage / 100));
    }
    if (options.bandsType && options.bandsType.deductionPercentage) {
        calculatedPrice -= Math.round(adjustedPrice * (options.bandsType.deductionPercentage / 100));
    }
    if (options.batteryHealth && options.batteryHealth.deductionPercentage) {
        calculatedPrice -= Math.round(adjustedPrice * (options.batteryHealth.deductionPercentage / 100));
    }
    if (options.strapCondition && options.strapCondition.deductionPercentage) {
        calculatedPrice -= Math.round(adjustedPrice * (options.strapCondition.deductionPercentage / 100));
    }
    if (options.bodyCondition && options.bodyCondition.deductionPercentage) {
        calculatedPrice -= Math.round(adjustedPrice * (options.bodyCondition.deductionPercentage / 100));
    }
    if (options.accessories && options.accessories.deductionPercentage) {
        calculatedPrice -= Math.round(adjustedPrice * (options.accessories.deductionPercentage / 100));
    }

      Object.keys(options).forEach((key) => {
    const option = options[key];
    if (key === 'repair' || key === 'faults' || key === 'cosmeticIssues') {
      option.forEach((selectedOption) => {
        // Deduct from the adjusted price
        calculatedPrice -= Math.round(adjustedPrice * (selectedOption.deductionPercentage / 100)); 
      });
    }
  });

    setFinalPrice(calculatedPrice);

  }, [product]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get('id');

    window.scrollTo({ top: 0, behavior: 'smooth' });

    const fetchData = async () => {
    try {
        const productResponse = await axiosInstance.get(`/api/watches?id=${id}`);
        const productData = productResponse.data;
        setProduct(productData);
        setFinalPrice(productData.maxPrice);

        const instantCashOption = productData.paymentOptions.find((option) => option.type === 'Instant Cash');
        if (instantCashOption) {
            const instantCashDeduction = Math.round(productData.maxPrice * (instantCashOption.deductionPercentage / 100));
            setInstantCashValue(productData.maxPrice - instantCashDeduction);
        }

        setSelectedOptions((prevOptions) => ({
            ...prevOptions,
            watchCaseSizes: productData.watchCaseSize, // Ensure this is set correctly
            watchCaseTypes: productData.watchCaseType,
            watchCaseFinishOption: productData.watchCaseFinish,
            bandsTypes: productData.bandsType,
            batteryHealthOptions: productData.batteryHealth,
            strapConditions: productData.strapCondition,
            bodyConditions: productData.bodyCondition,
            accessoriesOptions: productData.accessories,  // Ensure this line sets accessories options
            repairOptions: productData.repairs,
            paymentOptions: productData.paymentOptions,
            cosmeticIssueOptions: productData.cosmeticIssues,
            faultOptions: productData.faults,
        }));
        console.log('Product details:', productData);
    } catch (error) {
        console.error('Error fetching product details:', error);
        setErrorMessage('Error fetching product details. Please try again later.');
    }
    };

    fetchData();
  }, [location.search]);

useEffect(() => {
  if (product && !isPaymentSelected) {
    calculateFinalPrice(selectedOptions);
  }
}, [selectedOptions, product, calculateFinalPrice, isPaymentSelected]);


  const handleSelection = (category, option, deductionPercentage = 0) => {
  setErrorMessage('');

  setSelectedOptions((prevOptions) => {
    const newOptions = { ...prevOptions };

    // Repair handling (multiple selection allowed)
    if (category === 'repair') {
      const alreadySelectedIndex = newOptions.repair.findIndex((selected) => selected.repair === option.repair);
      if (alreadySelectedIndex >= 0) {
        newOptions.repair.splice(alreadySelectedIndex, 1); // Deselect
      } else {
        newOptions.repair.push({
          repair: option.repair,
          value: option.repair,
          deductionPercentage,
        }); // Select
      }
    }

    else if (category === 'watchCaseSize') {
      if (newOptions.watchCaseSize?.value === option) {
        newOptions.watchCaseSize = null; // Deselect
      } else {
        newOptions.watchCaseSize = { value: option, deductionPercentage }; // Select
      }
    }

    // Watch Case Type handling (single selection)
    else if (category === 'watchCaseType') {
      if (newOptions.watchCaseType?.value === option) {
        newOptions.watchCaseType = null; // Deselect
      } else {
        newOptions.watchCaseType = { value: option, deductionPercentage }; // Select
      }
    }

    // Watch Case Finish handling (single selection)
    else if (category === 'watchCaseFinishOption')
    {
      const selectedFinish = product.watchCaseFinish.find((finish) => finish.finish === option);

      if (newOptions[category]?.value === selectedFinish.finish) {
      newOptions[category] = null; // Deselect
    } else {
      newOptions[category] = { value: selectedFinish.finish, deductionPercentage: selectedFinish.deductionPercentage }; // Select
    }
}

    else if (category === 'watchCaseFinishOption') {
      const selectedFinish = product.watchCaseFinish.find((finish) => finish.finish === option);
      newOptions[category] = { value: selectedFinish.finish, deductionPercentage: selectedFinish.deductionPercentage };
    }


    // Bands Type handling (single selection)
    else if (category === 'bandsType') {
      if (newOptions.bandsType?.value === option) {
        newOptions.bandsType = null; // Deselect
      } else {
        newOptions.bandsType = { value: option, deductionPercentage }; // Select
      }
    }

    // Battery Health handling (single selection)
    else if (category === 'batteryHealth') {
      if (newOptions.batteryHealth?.value === option) {
        newOptions.batteryHealth = null; // Deselect
      } else {
        newOptions.batteryHealth = { value: option, deductionPercentage }; // Select
      }
    }

    
    // Faults handling (multiple selection allowed)
    else if (category === 'faults') {
      const alreadySelectedIndex = newOptions.faults.findIndex((selected) => selected._id === option._id);
      if (alreadySelectedIndex >= 0) {
        newOptions.faults.splice(alreadySelectedIndex, 1); // Deselect
      } else {
        newOptions.faults.push({
          _id: option._id,
          header: option.header,
          value: option.condition,
          deductionPercentage,
        }); // Select
      }
    }

    // Cosmetic Issues handling (multiple selection allowed)
    else if (category === 'cosmeticIssues') {
      const alreadySelectedIndex = newOptions.cosmeticIssues.findIndex((selected) => selected._id === option._id);
      if (alreadySelectedIndex >= 0) {
        newOptions.cosmeticIssues.splice(alreadySelectedIndex, 1); // Deselect
      } else {
        newOptions.cosmeticIssues.push({
          _id: option._id,
          header: option.header,
          value: option.condition,
          deductionPercentage,
        }); // Select
      }
    }

else if (category === 'paymentOption') {
  if (newOptions.paymentOption?.option === option.option) {
    newOptions.paymentOption = null; // Deselect
    setIsPaymentSelected(false); // Allow recalculation if no payment option is selected
  } else {
    newOptions.paymentOption = {
      option: option.option,
      deductionPercentage: option.deductionPercentage,
    };

    // Update final price based on selected payment option
    if (option.option === 'Instant Cash') {
      setFinalPrice(instantCashValue); // Set the final price to the calculated instant cash value
    } else if (option.option === 'Store Credit') {
      setFinalPrice(storeCreditValue); // Set the final price to store credit value (no deduction)
    }

    setIsPaymentSelected(true); // Prevent further recalculations
  }
}

    // Single selection for fields like watchCaseType, watchCaseFinish, etc.
    else if (['strapCondition', 'bodyCondition', 'accessories', 'paymentOption'].includes(category)) {
      if (newOptions[category]?.value === option) {
        newOptions[category] = null; // Deselect
      } else {
        newOptions[category] = { value: option, deductionPercentage }; // Select
      }
    }

    // Handling for isFaulty, isDamaged, isRepaired (single selection)
        else if (category === 'isFaulty') {
      newOptions[category] = option;
      if (option === 'No') {
        newOptions.faults = []; // Reset faults if "No"
        const damagedDisplayOption = product.cosmeticIssues.find((issue) => issue.header === 'Damaged Display');
        if (damagedDisplayOption && !newOptions.cosmeticIssueOptions.some((issue) => issue.header === 'Damaged Display')) {
          newOptions.cosmeticIssueOptions.push(damagedDisplayOption); // Add Damaged Display back if deselected
        }
      }
    } else if (category === 'isDamaged') {
      newOptions[category] = option;
      if (option === 'No') {
        // Reset cosmetic issues and associated conditions if "No"
        newOptions.cosmeticIssues = [];
        newOptions.strapCondition = [];
        newOptions.bodyCondition = [];
      }
    } else if (category === 'isRepaired') {
      newOptions[category] = option;
      if (option === 'No') {
        // Reset repairs if "No"
        newOptions.repair = [];
        newOptions.otherRepairText = '';
      }
    }

    // Handling text inputs (like otherRepairText)
    else if (category === 'otherRepairText') {
      newOptions.otherRepairText = option;
    }

        else {
      if (newOptions[category]?.value === option) {
        newOptions[category] = null; // Deselect
      } else {
        newOptions[category] = { value: option, deductionPercentage }; // Select
      }
    }

    return newOptions;
  });
};

const isStepValid = useCallback(() => {
  // Step 1: Watch case type, case finish, case size, and band type selection validation
  if (step === 1) {
    return (
      selectedOptions.watchCaseType &&
      selectedOptions.watchCaseFinishOption &&
      selectedOptions.watchCaseSize &&
      selectedOptions.bandsType
    );
  }

  // Step 2: Functionality, Battery Health, and Cosmetic Issues validation
  if (step === 2) {
    // Check if functionality and battery health have been selected
    if (!selectedOptions.isFaulty || !selectedOptions.batteryHealth) return false;

    // If "Yes" is selected for isFaulty, at least one fault should be selected
    if (selectedOptions.isFaulty === 'Yes' && !selectedOptions.faults.some(fault => fault.header && fault.value)) {
      return false;
    }

    // At least one cosmetic issue should be selected if the device is damaged
    if (selectedOptions.isDamaged === 'Yes' && !selectedOptions.cosmeticIssues.some(issue => issue.header)) {
      return false;
    }

    return true;
  }

  // Step 3: Repair validation
  if (step === 3) {
    if (!selectedOptions.isRepaired) return false;

    if (selectedOptions.isRepaired === 'Yes' && (!selectedOptions.repair.length || !selectedOptions.repair.some(repair => repair.repair && repair.value))) {
      return false;
    }

    return true;
  }

  // Step 4: Cosmetic Condition validation (strapCondition and bodyCondition)
  if (step === 4) {
    // Ensure `isDamaged` is selected (either "Yes" or "No")
    if (!selectedOptions.isDamaged) return false;

    // If "Yes" is selected for isDamaged, ensure both strapCondition and bodyCondition are selected
    if (selectedOptions.isDamaged === 'Yes') {
      if (!selectedOptions.strapCondition?.value || !selectedOptions.bodyCondition?.value) {
        return false;
      }
    }

    // If "No" is selected, still ensure that both strapCondition and bodyCondition are selected
    if (selectedOptions.isDamaged === 'No') {
      if (!selectedOptions.strapCondition?.value || !selectedOptions.bodyCondition?.value) {
        return false;
      }
    }

    return true; // Return true if all conditions are met
  }



  // Step 5: Accessories validation
  if (step === 5) {
    if (!selectedOptions.accessories) return false;

    return true;
  }

  // Step 6: Payment Option validation
  if (step === 6) {
    return !!selectedOptions.paymentOption;
  }

  // Step 7: Customer Details validation
  if (step === 7) {
    return (
      customerDetails.fullName &&
      customerDetails.whatsapp &&
      customerDetails.isInLahore &&
      customerDetails.buyingPreference &&
      (customerDetails.buyingPreference !== 'representative' || customerDetails.address)
    );
  }

  return true;
}, [step, selectedOptions, customerDetails]);

const validateSelection = () => {
  // Step 1: Validate watch case type, case finish, case size, and bands type
  if (step === 1) {
    if (!selectedOptions.watchCaseType) {
      setErrorMessage('Please select a watch case type.');
      return false;
    }
    if (!selectedOptions.watchCaseFinishOption) {
      setErrorMessage('Please select a watch case finish.');
      return false;
    }
    if (!selectedOptions.watchCaseSize) {
      setErrorMessage('Please select a watch case size.');
      return false;
    }
    if (!selectedOptions.bandsType) {
      setErrorMessage('Please select a band type.');
      return false;
    }
  }

  // Step 2: Validate functionality, battery health, and cosmetic issues
  if (step === 2) {
    if (!selectedOptions.isFaulty) {
      setErrorMessage('Please select if your watch is fully functional.');
      return false;
    }

    if (selectedOptions.isFaulty === 'Yes' && !selectedOptions.faults.some(fault => fault.header && fault.value)) {
      setErrorMessage('Please select at least one fault option.');
      return false;
    }

    if (!selectedOptions.isDamaged) {
      setErrorMessage('Please select if your watch is damaged or broken.');
      return false;
    }

    if (selectedOptions.isDamaged === 'Yes' && !selectedOptions.cosmeticIssues.some(issue => issue.header)) {
      setErrorMessage('Please select at least one cosmetic issue.');
      return false;
    }

    if (!selectedOptions.batteryHealth) {
      setErrorMessage('Please select your watch’s battery health.');
      return false;
    }
  }

  // Step 3: Validate repair history
  if (step === 3) {
    if (!selectedOptions.isRepaired) {
      setErrorMessage('Please select if anything on your watch is repaired or replaced.');
      return false;
    }

    if (selectedOptions.isRepaired === 'Yes' && (!selectedOptions.repair.length || !selectedOptions.repair.some(repair => repair.repair && repair.value))) {
      setErrorMessage('Please select at least one repair option.');
      return false;
    }
  }

  // Step 4: Validate cosmetic condition (strap condition and body condition)
  if (step === 4) {
    if (!selectedOptions.isDamaged) {
      setErrorMessage('Please select if your watch is damaged or broken.');
      return false;
    }

    if (selectedOptions.isDamaged === 'Yes') {
      if (!selectedOptions.strapCondition) {
        setErrorMessage('Please select the strap condition.');
        return false;
      }
      if (!selectedOptions.bodyCondition) {
        setErrorMessage('Please select the body condition.');
        return false;
      }
    }
  }

  // Step 5: Validate accessories
  if (step === 5) {
    if (!selectedOptions.accessories) {
      setErrorMessage('Please select an accessory option.');
      return false;
    }
  }

  // Step 6: Validate payment option
  if (step === 6) {
    if (!selectedOptions.paymentOption) {
      setErrorMessage('Please select a payment option.');
      return false;
    }
  }

  // Step 7: Validate customer details
  if (step === 7) {
    if (!customerDetails.fullName) {
      setErrorMessage('Please enter your full name.');
      return false;
    }
    if (!customerDetails.whatsapp || customerDetails.whatsapp.length < 11) { 
      setErrorMessage('Please enter a valid WhatsApp number.');
      return false;
    }
    if (!customerDetails.isInLahore) {
      setErrorMessage('Please select if you live in Lahore.');
      return false;
    }
    if (!customerDetails.buyingPreference) {
      setErrorMessage('Please select your buying preference.');
      return false;
    }
    if (customerDetails.buyingPreference === 'representative' && !customerDetails.address) {
      setErrorMessage('Please enter your address.');
      return false;
    }
  }

  setErrorMessage('');
  return true;
};

const handleNext = () => {
  if (!validateSelection()) {
    return;
  }

  setStep((prevStep) => {
    const newStep = prevStep + 1;

    // For the Payment Options step (step 6), set storeCreditValue and instantCashValue once
    if (newStep === 6) {
      // Store Credit should remain the final price
      setStoreCreditValue(finalPrice); // No further deductions

      // Instant Cash calculation based on its deduction percentage
      const instantCashOption = selectedOptions.paymentOptions?.find(
        (option) => option.option === 'Instant Cash'
      );
      if (instantCashOption) {
        const instantCashDeduction = Math.round(
          product.maxPrice * (instantCashOption.deductionPercentage / 100)
        );
        setInstantCashValue(finalPrice - instantCashDeduction);

        // If Instant Cash is already selected, set the final price to instant cash value
        if (selectedOptions.paymentOption?.option === 'Instant Cash') {
          setFinalPrice(finalPrice - instantCashDeduction);
        }
      }
    }

    return newStep;
  });

  window.scrollTo({ top: 0, behavior: 'smooth' });
};

useEffect(() => {
  if (isMobile && product?.watchCaseFinish) {
    const imageUrl = product.watchCaseFinish.find((finish) => finish.finish === selectedOptions.watchCaseFinishOption?.value)?.image || product.watchCaseFinish[0]?.image;

    // Preload the image
    const link = document.createElement('link');
    link.rel = 'preload';
    link.as = 'image';
    link.href = imageUrl;
    link.setAttribute('fetchpriority', 'high');
    document.head.appendChild(link);
  }
}, [isMobile, product, selectedOptions.watchCaseFinishOption]);


const handlePrevious = () => {
    setStep((prevStep) => {
      const newStep = prevStep - 1;
      calculateFinalPrice(selectedOptions);
      return newStep;
    });
    window.scrollTo({ top: 0, behavior: 'smooth' });
};

const handleSubmit = async () => {
  if (!validateSelection()) {
    return;
  }

  try {
    // Fetch IP and location data
    const ipLocationResponse = await axiosInstance.get('/api/inspections/get-ip-location');
    const { ip, city, region, country, latitude, longitude } = ipLocationResponse.data;

    const isInLahore = customerDetails.isInLahore === 'Yes';

    // Construct the inspection data
    const inspectionData = {
      modelName: product.modelName,
      maxPrice: product.maxPrice,
      finalPrice,
      watchCaseType: selectedOptions.watchCaseType?.value,
      watchCaseFinish: selectedOptions.watchCaseFinishOption?.value,
      watchCaseSize: selectedOptions.watchCaseSize?.value,
      bandsType: selectedOptions.bandsType?.value,
      batteryHealth: selectedOptions.batteryHealth?.value,
      isFaulty: selectedOptions.isFaulty,
      faults: selectedOptions.isFaulty === 'Yes' ? selectedOptions.faults.map(fault => ({
        header: fault.header, 
        description: fault.value
      })) : [],
      isRepaired: selectedOptions.isRepaired,
      repair: selectedOptions.isRepaired === 'Yes' ? selectedOptions.repair.map(repair => ({
        repair: repair.repair
      })) : [],
      isDamaged: selectedOptions.isDamaged,
      cosmeticIssues: selectedOptions.isDamaged === 'Yes' ? selectedOptions.cosmeticIssues.map(issue => ({
        header: issue.header, 
        description: issue.value
      })) : [],
      strapCondition: selectedOptions.strapCondition?.value,
      bodyCondition: selectedOptions.bodyCondition?.value,
      accessories: selectedOptions.accessories?.value,
      paymentOption: {
        option: selectedOptions.paymentOption?.option,
        deductionPercentage: selectedOptions.paymentOption?.deductionPercentage
      },
      // Customer details
      fullName: customerDetails.fullName,
      whatsapp: customerDetails.whatsapp,
      isInLahore,
      address: customerDetails.address,
      buyingPreference: customerDetails.buyingPreference,
      // IP and Location
      ipAddress: ip,
      location: { city, region, country, latitude, longitude },
    };

    await axiosInstance.post('/api/inspections', inspectionData);

    // Show confirmation dialog and redirect
    setShowPopup(true);
    setTimeout(() => {
      navigate('/'); // Redirect to home page after 3 seconds
    }, 3000); // 3000 milliseconds (3 seconds)
  } catch (error) {
    console.error('Error submitting inspection:', error);
    setErrorMessage('Failed to submit inspection. Please try again.');
  }
};

return (
  <div className="container px-2 mx-auto sm:mt-12 sm:mb-12 md:mb-18 md:mt-18 lg:mt-24 lg:mb-24">
    {product && (
      <>
        {/* Headings based on the step */}
        <div className="mt-0">
        {!isMobile && (
          <div className='bg-lime-100 py-10 px-4 sm:px-6 md:px-10 lg:px-16 rounded-3xl text-center hidden sm:block'>
        <div className='flex flex-col items-center text-center'>
  {step === 1 && (
    <>
      <h2 className="text-2xl font-semibold">Variant Options</h2>
      <p>Open the Watch app on your iPhone → Go to General → About to check the storage capacity. <br />Alternatively, on the watch itself, go to Settings → General → About.</p>
    </>
  )}
  
  {step === 2 && (
    <>
      <h2 className="text-2xl font-semibold">Functionality</h2>
      <p>Select any functional issues you are experiencing with your device.</p>
    </>
  )}

  {step === 3 && (
    <>
      <h2 className="text-2xl font-semibold">Repair History</h2>
      <p>Indicate if any parts of your phone have been repaired or replaced.</p>
    </>
  )}

  {step === 4 && (
    <>
      <h2 className="text-left text-2xl font-semibold">Cosmetic Condition</h2>
      <p>Choose the current condition of the phone's external parts (e.g., screen, back, sides).</p>
    </>
  )}

  {step === 5 && (
    <>
      <h2 className="text-left text-2xl font-semibold">Accessories & Custom Duty</h2>
      <p>Select the accessories that come with your phone, if any</p>
    </>
  )}
    {step === 6 && (
    <>
      <h2 className='text-left text-2xl font-semibold'>Estimated Device Value</h2>
      <p></p>
    </>
  )}
  {step === 7 && (
    <>
      <h2 className='text-left text-2xl font-semibold'>Customer Details</h2>
      <p></p>
    </>
  )}
</div>
          </div>

)}
</div>

        {/* Main content */}
  <div className={`flex flex-col md:flex-row gap-4 ${isMobile ? 'justify-center' : ''}`}>
  <div 
  className={`md:w-2/3 mt-6 ${isMobile ? 'w-full mx-auto' : ''} order-2 md:order-1`}
  style={{
    marginTop: isMobile ? '7.5rem' : '', // Adjusting for mobile and desktop
    paddingTop: isMobile ? '7.5rem' : '', // Reduce padding on mobile to minimize white space
    display: 'flex',
    flexDirection: 'column', 
    justifyContent: 'flex-start', // Ensures content starts from the top
  }}
>

<div className="bg-white shadow-lg rounded-lg p-4 mb-12">
              {/* Sticky image for mobile */}
{isMobile && product && product.watchCaseFinish && (
  <div className="fixed left-0 right-0 top-14 p-3 bg-white z-40 shadow-md">
    <div className="relative">
      <button
        onClick={() => {
          if (step === 1) {
            navigate('/watch');
          } else {
            handlePrevious();
          }
        }}
        className="absolute top-0 left-0 p-1 bg-white rounded-full"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={2}
          stroke="currentColor"
          className="w-6 h-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M15 19l-7-7 7-7"
          />
        </svg>
      </button>
<div className="w-full h-40" style={{ minHeight: '160px', maxHeight: '160px' }}>
  <img
    src={
      product.watchCaseFinish.find((finish) => finish.finish === selectedOptions.watchCaseFinishOption?.value)?.image ||
      product.watchCaseFinish[0]?.image
    }
    alt={product.modelName}
    className="w-full h-full object-contain"
    fetchpriority="high"
    style={{ opacity: 0.5, transition: 'opacity 0.5s ease-in-out' }}
    onLoad={(e) => (e.target.style.opacity = 1)}
  />
</div>

      <p className="text-center text-lg font-semibold mt-2">
        {product.modelName} {selectedOptions.watchCaseSize && `(${selectedOptions.watchCaseSize.value})`}
      </p>
    </div>
  </div>
)}

              {/* Step-based content */}
              <div className={isMobile ? 'mt-0' : 'mt-0'}>
                {/* Step 1: Variant Options */}
{step === 1 && (
  <div>
    {/* Watch Case Size */}
    <h3 className="mt-4 text-lg font-semibold">Watch Case Size</h3>
    <div className="grid grid-cols-2 md:grid-cols-4 gap-4 mt-2">
     {selectedOptions.watchCaseSizes?.map((size) => (
        <button
          key={size._id}
          className={`py-2 px-4 rounded-lg border ${
            selectedOptions.watchCaseSize && selectedOptions.watchCaseSize.value === size.size
              ? 'bg-black text-white font-semibold'
              : 'bg-white text-black border-gray-300 font-semibold'
          }`}
          onClick={() => handleSelection('watchCaseSize', size.size, size.deductionPercentage)}
        >
          {size.size}
        </button>
      ))}
    </div>

    {/* Watch Case Type */}
    <h3 className="mt-4 text-lg font-semibold">Watch Case Type</h3>
    <div className="grid grid-cols-2 md:grid-cols-4 gap-4 mt-2">
 {selectedOptions.watchCaseTypes?.map((option) => (
        <button
          key={option._id}
          className={`py-2 px-4 rounded-lg border ${
          selectedOptions.watchCaseType && selectedOptions.watchCaseType.value === option.caseType
              ? 'bg-black text-white font-semibold'
              : 'bg-white text-black border-gray-300 font-semibold'
          }`}
          onClick={() => handleSelection('watchCaseType', option.caseType, option.deductionPercentage)}
        >
          {option.caseType}
        </button>
      ))}
    </div>

    {/* Conditional rendering of Watch Case Finishes based on Watch Case Type selection */}
    {selectedOptions.watchCaseType && (
  <>
    <h3 className="mt-4 text-lg font-semibold">Watch Case Finish</h3>
    <div className="grid grid-cols-2 md:grid-cols-4 gap-4 mt-2">
      {product.watchCaseFinish
        ?.filter((finish) => {
          if (selectedOptions.watchCaseType?.value === 'Stainless Steel') {
            return finish.finish.includes('Stainless Steel');
          } else if (selectedOptions.watchCaseType?.value === 'Aluminum') {
            return !finish.finish.includes('Stainless Steel');
          }
          return true;
        })
        .map((option) => (
          <button
            key={option._id}
            className={`py-2 px-4 rounded-lg border ${
              selectedOptions.watchCaseFinishOption?.value === option.finish
                ? 'bg-black text-white font-semibold'
                : 'bg-white text-black border-gray-300 font-semibold'
            }`}
            onClick={() => handleSelection('watchCaseFinishOption', option.finish)}
          >
            {option.finish}
          </button>
        ))}
    </div>
  </>
)}



    {/* Bands Type */}
    <h3 className="mt-4 text-lg font-semibold">Bands Type</h3>
    <div className="grid grid-cols-2 md:grid-cols-4 gap-4 mt-2">
      {selectedOptions.bandsTypes?.map((option) => (
        <button
          key={option._id}
          className={`py-2 px-4 rounded-lg border ${
            selectedOptions.bandsType?.value === option.option
              ? 'bg-black text-white font-semibold'
              : 'bg-white text-black border-gray-300 font-semibold'
          }`}
          onClick={() => handleSelection('bandsType', option.option, option.deductionPercentage)}
        >
          {option.option}
        </button>
      ))}
    </div>
  </div>
)}




                {/* Step 2: Functionality */}
                {step === 2 && (
                  <div>
                    <h3 className="mt-4 mb-4 text-lg font-semibold">Please select the Battery Health of your watch:</h3>
                    <div className="grid grid-cols-2 md:grid-cols-4 gap-4 mt-2">
                      {selectedOptions.batteryHealthOptions?.map((option) => (
                        <button
                          key={option._id}
                          className={`py-2 px-4 rounded-lg border ${
                            selectedOptions.batteryHealth?.value === option.health
                              ? 'bg-black text-white font-semibold'
                              : 'bg-white text-black border-gray-300 font-semibold'
                          }`}
                          onClick={() => handleSelection('batteryHealth', option.health, option.deductionPercentage)}
                        >
                          {option.health}
                        </button>
                      ))}
                    </div>

                    <h3 className="mt-8 mb-4 text-lg font-semibold">Does your watch have any functional problem(s)?</h3>
                    <div className="flex gap-2 mt-2">
                      <button
                        className={`py-2 px-4 rounded-lg ${
                          selectedOptions.isFaulty === 'Yes' ? 'bg-black text-white font-semibold' : 'bg-gray-100 text-black font-semibold'
                        }`}
                        onClick={() => handleSelection('isFaulty', 'Yes')}
                      >
                        Yes
                      </button>
                      <button
                        className={`py-2 px-4 rounded-lg ${
                          selectedOptions.isFaulty === 'No' ? 'bg-black text-white font-semibold' : 'bg-gray-100 text-black font-semibold'
                        }`}
                        onClick={() => handleSelection('isFaulty', 'No')}
                      >
                        No
                      </button>
                    </div>
                                        {selectedOptions.isFaulty === 'Yes' && (
                      <>
                        <h3 className="mt-4 mb-4 text-lg font-semibold">Please select the problem you are facing:</h3>
                        <div className="grid grid-cols-2 md:grid-cols-4 gap-4 mt-4">
  {selectedOptions.faultOptions?.map((option) => (
    <div
      key={option._id}
      className={`p-4 rounded-lg cursor-pointer border text-center ${
        selectedOptions.faults.some((selected) => selected._id === option._id)
          ? 'border-black font-semibold'
          : 'border-gray-300 bg-white font-semibold'
      }`}
      onClick={() => handleSelection('faults', option, option.deductionPercentage)}
    >
      {/* Header */}
      <h4 className="font-semibold text-lg">{option.header}</h4>
      
      {/* Description */}
      <p className="text-xs font-light overflow-hidden text-center justify-center">{option.condition}</p>

      {/* Check Icon */}
      {selectedOptions.faults.some((selected) => selected._id === option._id) && (
        <CheckCircleIcon className="absolute top-2 right-2 h-6 w-6 text-black" />
      )}
    </div>
  ))}
</div>

                      </>
                    )}
                    <div>
  <h3 className="mt-4 mb-4 text-lg font-semibold">Is your watch damaged or broken?</h3>
  <div className="flex gap-2 mt-4">
    <button
      className={`py-2 px-4 rounded-lg ${
        selectedOptions.isDamaged === 'Yes' ? 'bg-black text-white font-semibold' : 'bg-gray-100 text-black font-semibold'
      }`}
      onClick={() => handleSelection('isDamaged', 'Yes')}
    >
      Yes
    </button>
    <button
      className={`py-2 px-4 rounded-lg ${
        selectedOptions.isDamaged === 'No' ? 'bg-black text-white font-semibold' : 'bg-gray-100 text-black font-semibold'
      }`}
      onClick={() => handleSelection('isDamaged', 'No')}
    >
      No
    </button>
  </div>

  {selectedOptions.isDamaged === 'Yes' && (
    <>
      <h3 className="mt-4 mb-4 text-lg font-semibold">Please select the damaged part(s):</h3>
      <div className="grid grid-cols-2 md:grid-cols-4 gap-4 mt-4">
  {selectedOptions.cosmeticIssueOptions
    ?.filter(
      (option) =>
        option.header !== 'Damaged Display' ||
        !selectedOptions.faults.some((fault) => fault.condition === 'Dead Pixels/Spots/Lines')
    )
    .map((option) => (
      <div
        key={option._id}
        className={`p-4 rounded-lg cursor-pointer border text-center ${
          selectedOptions.cosmeticIssues.some((selected) => selected._id === option._id)
            ? 'border-black font-semibold'
            : 'border-gray-300 bg-white font-semibold'
        }`}
        onClick={() => handleSelection('cosmeticIssues', option, option.deductionPercentage)}
      >
        {/* Header */}
        <h4 className="font-semibold text-lg">{option.header}</h4>

        {/* Condition */}
        <p className="text-xs font-light overflow-hidden text-center justify-center">
          {option.condition}
        </p>

        {/* Check Icon */}
        {selectedOptions.cosmeticIssues.some((selected) => selected._id === option._id) && (
          <CheckCircleIcon className="absolute top-2 right-2 h-6 w-6 text-black" />
        )}
      </div>
    ))}
</div>
    </>
  )}
</div>

                  </div>
                )}

                {/* Step 3: Repair History */}
                {step === 3 && (
                  <div>
                    <h3 className="mt-4 text-lg font-semibold">Is any part of your watch repaired or replaced?</h3>
                    <div className="flex gap-2 mt-4 mb-4">
                      <button
                        className={`py-2 px-4 rounded-lg ${
                          selectedOptions.isRepaired === 'Yes' ? 'bg-black text-white font-semibold' : 'bg-gray-100 text-black font-semibold'
                        }`}
                        onClick={() => handleSelection('isRepaired', 'Yes')}
                      >
                        Yes
                      </button>
                      <button
                        className={`py-2 px-4 rounded-lg ${
                          selectedOptions.isRepaired === 'No' ? 'bg-black text-white font-semibold' : 'bg-gray-100 text-black font-semibold'
                        }`}
                        onClick={() => handleSelection('isRepaired', 'No')}
                      >
                        No
                      </button>
                    </div>

{selectedOptions.isRepaired === 'Yes' && (
  <>
    <h3 className="mt-4 mb-4 text-lg font-semibold">Please select the repair/replacement done:</h3>
            <div className="grid grid-cols-2 md:grid-cols-4 gap-4 mt-2">
          {selectedOptions.repairOptions?.map((option) => (
            <button
              key={option.repair}
              className={`py-2 px-4 rounded-lg border ${
                selectedOptions.repair.some((selected) => selected.repair === option.repair)
                  ? 'bg-black text-white font-semibold'
                  : 'bg-white text-black border-gray-300 font-semibold'
              }`}
              onClick={() => handleSelection('repair', option, option.deductionPercentage)}
            >
              {option.repair}
            </button>
          ))}
        </div>

    {selectedOptions.repair.some((selected) => selected.repair === 'Other Repairs') && (
      <div className="mt-4 mb-4">
        <h3 className="text-lg font-semibold">Please describe what was repaired or replaced (max 140 characters):</h3>
        <input
          type="text"
          maxLength={140}
          value={selectedOptions.otherRepairText}
          onChange={(e) => handleSelection('otherRepairText', e.target.value)}
          placeholder="Describe the repair or replacement..."
          className="w-full p-2 mt-2 border font-semibold border-gray-300 rounded-lg"
        />
      </div>
    )}
  </>
)}

                  </div>
                )}

                {/* Step 4: Cosmetic Condition */}
{step === 4 && (
  <div>
    <h3 className="mt-4 mb-4 text-lg font-semibold">Please select the condition of the strap:</h3>
    
    {/* Displaying Strap Condition Options in a Row (Grid) */}
    <div className="grid grid-cols-2 md:grid-cols-4 gap-4 mt-4">
      {selectedOptions.strapConditions?.map((option) => (
        <div
          key={option._id}
          className={`p-4 rounded-lg cursor-pointer border text-center ${
            selectedOptions.strapCondition?.value === option.condition
              ? 'border-black font-semibold'
              : 'border-gray-300 bg-white font-semibold'
          }`}
          onClick={() => handleSelection('strapCondition', option.condition, option.deductionPercentage)}
        >

          {/* Header */}
          <h4 className="font-semibold text-lg">{option.header}</h4>
          
          <p className="text-xs font-light overflow-hidden text-center justify-center">{option.condition}</p>



          {/* Check Icon */}
          {selectedOptions.strapCondition?.value === option.condition && (
            <CheckCircleIcon className="absolute top-2 right-2 h-6 w-6 text-black" />
          )}
        </div>
      ))}
    </div>
    <h3 className="mt-4 mb-4 text-lg font-semibold">Please select the condition of the body:</h3>
    
    {/* Displaying Body Condition Options in a Row (Grid) */}
    <div className="grid grid-cols-2 md:grid-cols-4 gap-4 mt-4">
      {selectedOptions.bodyConditions?.map((option) => (
        <div
          key={option._id}
          className={`p-4 rounded-lg cursor-pointer border text-center ${
            selectedOptions.bodyCondition?.value === option.condition
              ? 'border-black font-semibold'
              : 'border-gray-300 bg-white font-semibold'
          }`}
          onClick={() => handleSelection('bodyCondition', option.condition, option.deductionPercentage)}
        >

          {/* Header */}
          <h4 className="font-semibold text-lg">{option.header}</h4>

          <p className="text-xs font-light overflow-hidden text-center justify-center">{option.condition}</p>

          {/* Check Icon */}
          {selectedOptions.bodyCondition?.value === option.condition && (
            <CheckCircleIcon className="absolute top-2 right-2 h-6 w-6 text-black" />
          )}
        </div>
      ))}
    </div>
  </div>
)}

                {/* Step 5: Accessories */}
                {step === 5 && (
                  <div>
                    <h3 className="mt-4 mb-4 text-lg font-semibold">Accessories</h3>
                                      <div className="grid grid-cols-2 md:grid-cols-4 gap-4 mt-4">
  {selectedOptions.accessoriesOptions?.map((option) => (
    <button
      key={option._id}
      className={`py-2 px-4 rounded-lg border ${
        selectedOptions.accessories?.value === option.option
          ? 'bg-black text-white font-semibold'
          : 'bg-white text-black border-gray-300 font-semibold'
      }`}
      onClick={() => handleSelection('accessories', option.option, option.deductionPercentage)}
    >
      {option.option}
    </button>
  ))}
</div>
                  </div>
                )}

                {/* Step 6: Payment Option */}
                {step === 6 && (
                  <div>
                    <h3 className="mt-4 mb-4 text-lg font-semibold">Please select from the following payment options: </h3>
                    <div className="grid grid-cols-2 md:grid-cols-4 gap-4 mt-2">
                      {selectedOptions.paymentOptions.map((option) => (
                        <button
                          key={option._id}
                          className={`py-2 px-4 rounded-lg border ${
                            selectedOptions.paymentOption?.option === option.option
                              ? 'bg-black text-white font-semibold'
                              : 'bg-white text-black border-gray-300 font-semibold'
                          }`}
                          onClick={() => handleSelection('paymentOption', option)}
                        >
                          {option.option === 'Instant Cash'
                            ? `Instant Cash (Rs: ${instantCashValue.toLocaleString()})`
                            : `Store Credit (Rs: ${storeCreditValue.toLocaleString()})`
                          }
                        </button>
                      ))}
                    </div>

                    <div className="relative mt-4 bg-indigo-100 text-indigo-900 p-4 rounded-lg shadow-lg w-full">
                      <strong>Why Choose Store Credit?</strong>
                      <ul className="mt-2 text-sm">
                        <li>- Store Credit offers a higher value compared to Instant Cash.</li>
                        <li>- You can use this Credit to buy an upgrade from our Store or cash it out to your bank account after 7 days.</li>
                      </ul>
                    </div>
                  </div>
                )}

                {/* Step 7: Customer Details */}
                {step === 7 && (
                  <div>
                    <div className="mt-4">
                      <div className="w-10/12">
                        <input
                          type="text"
                          value={customerDetails.fullName}
                          onChange={(e) => handleCustomerDetailsChange('fullName', e.target.value)}
                          placeholder="Enter your name here..."
                          className="w-full p-2 mt-2 border font-semibold border-gray-300 rounded-lg"
                        />
                      </div>
                    </div>

                    <div className="mt-4">
                      <div className="w-10/12">
                        <input
                          type="text"
                          value={customerDetails.whatsapp}
                          onChange={(e) => {
                            let input = e.target.value.replace(/\D/g, ''); // Remove non-numeric characters
                            handleCustomerDetailsChange('whatsapp', input); // Set the number as is
                          }}
                          placeholder="(03XXXXXXXXX)"
                          className="w-full p-2 mt-2 border font-semibold border-gray-300 rounded-lg"
                          maxLength={11} // Limit the input to 11 characters
                        />
                      </div>
                    </div>

                    <div className="mt-4">
                      <label className="block font-semibold">Do you live in Lahore?</label>
                      <div className="flex gap-2 mt-2">
                        <button
                          className={`py-2 px-4 rounded-lg ${
                            customerDetails.isInLahore === 'Yes' ? 'bg-black text-white font-semibold' : 'bg-gray-100 text-black font-semibold'
                          }`}
                          onClick={() => handleCustomerDetailsChange('isInLahore', 'Yes')}
                        >
                          Yes
                        </button>
                        <button
                          className={`py-2 px-4 rounded-lg ${
                            customerDetails.isInLahore === 'No' ? 'bg-black text-white font-semibold' : 'bg-gray-100 text-black font-semibold'
                          }`}
                          onClick={() => handleCustomerDetailsChange('isInLahore', 'No')}
                        >
                          No
                        </button>
                      </div>
                    </div>

                    <div className="mt-4">
                      <label className="block font-semibold">How would you like us to buy your watch?</label>
                      <div className="flex gap-2 mt-2">
                        <button
                          className={`py-2 px-4 rounded-lg ${
                            customerDetails.buyingPreference === 'bring' ? 'bg-black text-white font-semibold' : 'bg-gray-100 text-black font-semibold'
                          }`}
                          onClick={() => handleCustomerDetailsChange('buyingPreference', 'bring')}
                        >
                          I'll bring/send it to your office
                        </button>
                        <button
                          className={`py-2 px-4 rounded-lg ${
                            customerDetails.buyingPreference === 'representative' ? 'bg-black text-white font-semibold' : 'bg-gray-100 text-black font-semibold'
                          }`}
                          onClick={() => handleCustomerDetailsChange('buyingPreference', 'representative')}
                        >
                          Send your representative to my address
                        </button>
                      </div>
                    </div>

                    {customerDetails.buyingPreference === 'representative' && (
                      <div className="mt-4">
                        <label className="block font-semibold">Enter your address:</label>
                        <input
                          type="text"
                          value={customerDetails.address || ''}
                          onChange={(e) => handleCustomerDetailsChange('address', e.target.value)}
                          placeholder="Enter your address here"
                          className="w-full p-2 mt-2 border font-semibold border-gray-300 rounded-lg"
                        />
                      </div>
                    )}
                  </div>
                )}
                <div 
  className={`${
    isMobile ? 'fixed bottom-0 left-0 right-0 w-full z-50 p-4' : 'mt-12 mb-6'
  } flex justify-center items-center gap-4 bg-opacity-70 mx-auto transition-all duration-500`}
>
  {!isMobile && step >= 1 && step <= 7 && (
    <button
      className="py-2 px-4 font-urbanist font-semibold rounded-3xl transition border bg-white text-black hover:bg-gray-100 hover:border-black border-gray-300"
      onClick={() => {
        if (step === 1) {
          navigate('/watch');
        } else {
          handlePrevious();
        }
      }}
    >
      Go Back
    </button>
  )}

  {!isMobile && step < 7 && (
    <button
      className={`py-2 px-4 font-urbanist font-semibold rounded-3xl transition ${
        isStepValid() ? 'bg-customGreen text-white cursor-pointer' : 'bg-gray-300 text-gray-500 cursor-not-allowed'
      }`}
      onClick={isStepValid() ? handleNext : null}
      disabled={!isStepValid()}
    >
      Next
    </button>
  )}

  {isMobile && step < 7 && (
    <button
      className={`py-2 px-4 font-urbanist font-semibold rounded-lg w-full max-w-xs bg-customGreen text-white transition-all duration-500 transform ${
        isStepValid() ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-full'
      }`}
      onClick={isStepValid() ? handleNext : null}
      disabled={!isStepValid()}
    >
      Next
    </button>
  )}

  {isMobile && step === 7 && (
    <button
      className={`py-2 px-4 font-urbanist font-semibold rounded-lg w-full max-w-xs bg-customGreen text-white transition-all duration-500 transform ${
        isStepValid() ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-full'
      }`}
      onClick={isStepValid() ? handleSubmit : null}
      disabled={!isStepValid()}
    >
      Get exact value
    </button>
  )}

  {!isMobile && step === 7 && (
    <button
      className={`py-2 px-4 font-urbanist font-semibold rounded-3xl transition ${
        isStepValid() ? 'bg-customGreen text-white cursor-pointer' : 'bg-gray-300 text-gray-500 cursor-not-allowed'
      }`}
      onClick={isStepValid() ? handleSubmit : null}
      disabled={!isStepValid()}
    >
      Get exact value
    </button>
  )}
</div>
              </div>
            </div>
          </div>

          {/* Sticky right sidebar (desktop only) */}
{!isMobile && (
  <div className="md:w-1/3 order-1 md:order-2">
    <div className="sticky top-16">
      <div className="bg-white shadow-lg rounded-lg p-4">
        <div className="flex gap-2 items-center mb-4">
        <img
  src={
    selectedOptions.watchCaseFinishOption?.value
      ? product.watchCaseFinish.find((finish) => finish.finish === selectedOptions.watchCaseFinishOption.value)?.image
      : product.watchCaseFinish[0]?.image
  }
  alt={product.modelName}
  className="w-12 h-12 object-contain"
/>

          <p className="font-semibold">
            {product.modelName} {selectedOptions.watchCaseSize && ` (${selectedOptions.watchCaseSize.value})`}
          </p>
        </div>

        <hr className="my-4" />

        <h3 className="text-lg font-semibold">Watch Evaluation</h3>
        <ul className="list-disc pl-4 mt-2">
            {selectedOptions.watchCaseType && (
                <li>Case Type: {selectedOptions.watchCaseType.value}</li>
            )}
            {selectedOptions.watchCaseSize && (
                <li>Case Size: {selectedOptions.watchCaseSize.value}</li>
            )}
            {selectedOptions.bandsType && (
                <li>Bands Type: {selectedOptions.bandsType.value}</li>
            )}
          {selectedOptions.batteryHealth && (
            <li>Battery Health: {selectedOptions.batteryHealth.value}</li>
          )}
          {selectedOptions.faults.length > 0 && (
            <li>Faults: {selectedOptions.faults.map((fault) => fault.value).join(', ')}</li>
          )}
          {selectedOptions.repair.length > 0 && (
            <li>
              Repair History: {selectedOptions.repair.map((repair) => repair.repair).join(', ')}
            </li>
          )}
          {selectedOptions.cosmeticIssues.length > 0 && (
            <li>Cosmetic Issues: {selectedOptions.cosmeticIssues.map((issue) => issue.value).join(', ')}</li>
          )}
          {selectedOptions.strapCondition && (
            <li>Strap Condition: {selectedOptions.strapCondition.value}</li>
          )}
          {selectedOptions.bodyCondition && (
            <li>Body Condition: {selectedOptions.bodyCondition.value}</li>
          )}
          {selectedOptions.accessories && <li>Accessories: {selectedOptions.accessories.value}</li>}
          <li>
            Estimated Price: {selectedOptions.paymentOption?.option === 'Instant Cash'
              ? instantCashValue // Use instantCashValue directly if Instant Cash is selected
              : storeCreditValue // Use storeCreditValue for Store Credit
            } PKR
          </li>
          <li className="font-bold text-lg mt-2">
            Final Price: {finalPrice} PKR
          </li>
        </ul>
      </div>
    </div>
  </div>
)}

        </div>

        {/* Error message */}
        {errorMessage && <div className="mt-4 text-red-500">{errorMessage}</div>}

        {/* Success confirmation */}
        {showPopup && (
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
            <div className="bg-white p-6 rounded-lg shadow-lg max-w-sm w-full mx-4 md:max-w-md lg:max-w-lg">
              <CheckCircleIcon className="h-12 w-12 text-green-500 mx-auto" />
              <h2 className="text-center text-xl md:text-2xl font-semibold mt-4">Submission Successful!</h2>
              <p className="text-center mt-2 text-sm md:text-base">
                You will be redirected to the home page shortly.
              </p>
            </div>
          </div>
        )}
      </>
    )}
  </div>
);

};

export default WatchDetails;