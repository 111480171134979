import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import IPhone from './components/iPhone';
import Samsung from './components/Samsung';
import IPad from './components/iPad';
import Home from './components/Home';
import Navbar from './components/Navbar';
import IPhoneDetails from './components/iPhoneDetails';
import IPadDetails from './components/iPadDetails';
import Footer from './components/Footer';
import Header from './components/Header';
import { AnimatePresence, motion } from 'framer-motion';
import SamsungDetails from './components/SamsungDetails';
import MacBook from './components/Macbook';
import MacBookDetails from './components/MacbookDetails';
import Watch from './components/Watch';
import WatchDetails from './components/WatchDetails';

function App() {
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();

  useEffect(() => {
    const fetchData = async () => {
      // Simulate data loading
      await new Promise((resolve) => setTimeout(resolve, 1000));
      setIsLoading(false);
    };
    fetchData();
  }, []);

  return (
    <div className="flex flex-col min-h-screen">
      <Header />
      <Navbar />
      <main className="flex-grow mt-5" style={{ minHeight: 'calc(100vh - 200px)' }}>
        {isLoading ? (
          <div className="flex-grow flex items-center justify-center bg-white">
            <div className="loader">Loading...</div>
          </div>
        ) : (
          <AnimatePresence mode="wait">
            <Routes location={location} key={location.pathname}>
            <Route
              path="/"
              element={
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1}}
                  exit={{ opacity: 0}}
                  transition={{ duration: 0.5 }}
                >
                  <Home />
                </motion.div>
              }
            />
            <Route
              path="/iphone"
              element={
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1}}
                  exit={{ opacity: 0}}
                  transition={{ duration: 0.5 }}
                >
                  <IPhone />
                </motion.div>
              }
            />
            <Route
              path="/ipad"
              element={
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1}}
                  exit={{ opacity: 0}}
                  transition={{ duration: 0.5 }}
                >
                  <IPad />
                </motion.div>
              }
            />
            <Route
              path="/samsung"
              element={
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1}}
                  exit={{ opacity: 0}}
                  transition={{ duration: 0.5 }}
                >
                  <Samsung />
                </motion.div>
              }
            />
            <Route
              path="/macbook"
              element={
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1}}
                  exit={{ opacity: 0}}
                  transition={{ duration: 0.5 }}
                >
                  <MacBook />
                </motion.div>
              }
            />
            <Route
              path="/watch"
              element={
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1}}
                  exit={{ opacity: 0}}
                  transition={{ duration: 0.5 }}
                >
                  <Watch />
                </motion.div>
              }
            />
            <Route
              path="/product/iphone"
              element={
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1}}
                  exit={{ opacity: 0}}
                  transition={{ duration: 0.5 }}
                >
                  <IPhoneDetails />
                </motion.div>
              }
            />
            <Route
              path="/product/ipad"
              element={
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1}}
                  exit={{ opacity: 0}}
                  transition={{ duration: 0.5 }}
                >
                  <IPadDetails />
                </motion.div>
              }
            />
                        <Route
              path="/product/samsung"
              element={
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1}}
                  exit={{ opacity: 0}}
                  transition={{ duration: 0.5 }}
                >
                  <SamsungDetails />
                </motion.div>
              }
            />
                                    <Route
              path="/product/macbook"
              element={
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1}}
                  exit={{ opacity: 0}}
                  transition={{ duration: 0.5 }}
                >
                  <MacBookDetails />
                </motion.div>
              }
            />
              <Route
              path="/product/watch"
              element={
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1}}
                  exit={{ opacity: 0}}
                  transition={{ duration: 0.5 }}
                >
                  <WatchDetails />
                </motion.div>
              }
            />
          </Routes>
          </AnimatePresence>
        )}
      </main>
      <Footer />
    </div>
  );
}

function AppWithRouter() {
  return (
    <Router>
      <App />
    </Router>
  );
}

export default AppWithRouter;

