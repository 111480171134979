import React from 'react';
import { FaWhatsapp, FaMap, FaEnvelope, FaPhone } from 'react-icons/fa';

const Footer = () => {
  return (
    <footer className="bg-gray-100 py-10 px-5 md:px-20 shadow-md mt-64 w-full relative">
      {/* Desktop layout applies from 1025px onwards */}
      <div className="hidden xl:grid grid-cols-4 gap-8 container mx-auto">
        {/* REGEN */}
<div className="space-y-2">
  {/* Adding the Cloudinary image and the ADISA text */}
  <div className="space-y-2">
    <img src="https://res.cloudinary.com/dl1kjmaoq/image/upload/f_auto,q_auto/v1/static/m4gnwmpxzmss60wydlki" alt="ADISA Certified" className="w-1/2 h-auto" />
    <p className="text-gray-500 font-urbanist text-sm">ADISA Certified Device Erasure!</p>
  </div>
</div>



        {/* Pages */}
        <div className="space-y-2">
          <h6 className="text-lg font-bold font-urbanist mb-2 pl-12">Services</h6>
          <ul className="space-y-2 pl-12">
            <li><button className="block font-urbanist text-gray-700 hover:text-gray-900">Home V1</button></li>
            <li><button className="block font-urbanist text-gray-700 hover:text-gray-900">Home V2</button></li>
            <li><button className="block font-urbanist text-gray-700 hover:text-gray-900">Home V3</button></li>
            <li><button className="block font-urbanist text-gray-700 hover:text-gray-900">About</button></li>
            <li><button className="block font-urbanist text-gray-700 hover:text-gray-900">Services</button></li>
          </ul>
        </div>

        {/* Utility Pages */}
        <div className="space-y-2">
          <h6 className="text-lg font-bold font-urbanist mb-2">Legal</h6>
          <ul className="space-y-2">
            <li><button className="block font-urbanist text-gray-700 hover:text-gray-900">Start here</button></li>
            <li><button className="block font-urbanist text-gray-700 hover:text-gray-900">Style guide</button></li>
            <li><button className="block font-urbanist text-gray-700 hover:text-gray-900">404 not found</button></li>
            <li><button className="block font-urbanist text-gray-700 hover:text-gray-900">Password protected</button></li>
            <li><button className="block font-urbanist text-gray-700 hover:text-gray-900">Licenses</button></li>
          </ul>
        </div>

        {/* Contact Us */}
        <div className="space-y-4">
          <h6 className="text-lg font-bold font-urbanist mb-2">Help</h6>
                    {/* <div className="flex items-center mb-2">
            <InformationCircleIcon className="h-5 w-5 text-gray-700 mr-4" />
            <p className="text-gray-500 font-urbanist text-sm">Tutorials</p>
          </div> */}
           <div
  className="flex items-center mb-2 cursor-pointer"
  onClick={() => window.open('https://api.whatsapp.com/send?phone=923111005569', '_blank')}
>
  <FaWhatsapp className="h-5 w-5 text-gray-700 mr-4" />
  <p className="text-gray-500 font-urbanist text-sm">WhatsApp Chat</p>
</div>

          <div className="flex items-center mb-2">
            <FaMap className="h-5 w-5 text-gray-700 mr-4" />
            <p className="text-gray-500 font-urbanist text-sm">297 St 10, Sector Y, DHA Phase 3, Lahore</p>
          </div>
          <div className="flex items-center mb-2">
            <FaEnvelope className="h-5 w-5 text-gray-700 mr-4" />
            <p className="text-gray-500 font-urbanist text-sm">support@regen.pk</p>
          </div>
          <div className="flex items-center mb-2">
            <FaPhone className="h-5 w-5 text-gray-700 mr-4" />
            <p className="text-gray-500 font-urbanist text-sm">(+92) 311-1005569</p>
          </div>
        </div>

{/* Image and Pink CTA for desktop */}

<div className="hidden xl:block relative col-span-4 rounded-3xl">
  <div className="bg-pink-100 rounded-3xl py-10 md:py-24 relative overflow-visible mb-24 mt-12 flex flex-col items-center justify-center" style={{ paddingLeft: '250px', paddingRight: '250px' }}>
    <h4 className="text-4xl md:text-5xl font-semibold font-urbanist text-gray-800 text-center leading-tight">
      Upgrade with a Great<br /> Certified Used Device!
    </h4>
    <div className="flex justify-center mt-8 space-x-4">
      <button className="bg-black text-white rounded-full px-8 py-3 font-semibold font-urbanist shadow-md hover:bg-gray-800">
        Upgrade Your Device
      </button>
      <button className="text-black font-urbanist font-semibold flex items-center space-x-2">
        <span>Browse all devices</span>
        <span>→</span>
      </button>
    </div>
  </div>

  {/* Adjust the positioned image for safe area */}
  <img
    loading="lazy"
    src="https://res.cloudinary.com/dl1kjmaoq/image/upload/f_auto,q_auto/v1/static/tgsgjlrzkkz2khhvpt2x"
    alt="Certified Pre-Owned Device"
    className="absolute bottom-[-125px] left-[-80px] w-[200px] md:w-[500px]"
    style={{ zIndex: 0 }} // Lower z-index to avoid interfering with nav links
  />
</div>


  </div>

      {/* Tablet layout (1024px - 1279px) */}
<div className="hidden md:grid xl:hidden grid-cols-2 gap-8 container mx-auto">
  {/* First row: REGEN and Pages */}
  <div className="col-span-1 grid grid-cols-2 gap-4">
<div className="space-y-6">
  {/* Adding the Cloudinary image and the ADISA text */}
  <div className="space-y-6">
    <img src="https://res.cloudinary.com/dl1kjmaoq/image/upload/f_auto,q_auto/v1/static/m4gnwmpxzmss60wydlki" alt="ADISA Certified" className="w-1/2 h-auto" />
    <p className="text-gray-500 font-urbanist text-sm">ADISA Certified Device Erasure!</p>
  </div>
</div>
    <div className="space-y-6 text-left">
      <h6 className="text-lg font-bold font-urbanist mb-2">Services</h6>
      <ul className="space-y-2">
        <li><button className="block font-urbanist text-gray-700 hover:text-gray-900">Home V1</button></li>
        <li><button className="block font-urbanist text-gray-700 hover:text-gray-900">Home V2</button></li>
        <li><button className="block font-urbanist text-gray-700 hover:text-gray-900">Home V3</button></li>
      </ul>
    </div>
  </div>

  {/* Second row: Utility Pages and Contact Us */}
  <div className="col-span-1 grid grid-cols-2 gap-4">
    <div className="space-y-6">
      <h6 className="text-lg font-bold font-urbanist mb-2">Legal</h6>
      <ul className="space-y-2">
        <li><button className="block font-urbanist text-gray-700 hover:text-gray-900">Start here</button></li>
        <li><button className="block font-urbanist text-gray-700 hover:text-gray-900">Style guide</button></li>
        <li><button className="block font-urbanist text-gray-700 hover:text-gray-900">404 not found</button></li>
      </ul>
    </div>

    <div className="space-y-6 text-left">
      <h6 className="text-lg font-bold font-urbanist mb-2">Help</h6>
           <div
  className="flex items-center mb-2 cursor-pointer"
  onClick={() => window.open('https://api.whatsapp.com/send?phone=923111005569', '_blank')}
>
  <FaWhatsapp className="h-5 w-5 text-gray-700 mr-2" />
  <p className="text-gray-500 font-urbanist text-sm">WhatsApp Chat</p>
</div>
      <div className="flex justify-start items-center mb-2">
        <FaMap className="h-5 w-5 text-gray-700 mr-2" /> {/* Increased size */}
        <p className="text-gray-500 font-urbanist text-sm">297 St 10, Sector Y, DHA Phase 3, Lahore</p>
      </div>
      <div className="flex justify-start items-center mb-2">
        <FaEnvelope className="h-5 w-5 text-gray-700 mr-2" /> {/* Increased size */}
        <p className="text-gray-500 font-urbanist text-sm">support@regen.pk</p>
      </div>
      <div className="flex justify-start items-center mb-2">
        <FaPhone className="h-5 w-5 text-gray-700 mr-2" /> {/* Increased size */}
        <p className="text-gray-500 font-urbanist text-sm">(+92) 311-1005569</p>
      </div>
    </div>
  </div>

  {/* Third row: Pink CTA with Image for tablets */}
<div className="col-span-2 relative w-full rounded-3xl">
  <div className="bg-pink-100 rounded-3xl relative text-center flex flex-col justify-start items-end px-8 py-16 w-full" style={{ paddingLeft: '350px' }}>
    <h4 className="text-2xl md:text-3xl font-semibold font-urbanist text-gray-800 mt-0 leading-relaxed text-right">
      Upgrade with a Great Certified Used Device!
    </h4>
    <div className="flex flex-col items-end mt-4 space-y-4">
      <button className="bg-black text-white rounded-full px-8 py-3 font-semibold font-urbanist shadow-md hover:bg-gray-800">
        Upgrade Your Device
      </button>
      <button className="text-black font-urbanist font-semibold text-lg">Browse all devices →</button>
    </div>
  </div>

  {/* Positioned image */}
  <img
    loading='lazy'
    src="https://res.cloudinary.com/dl1kjmaoq/image/upload/f_auto,q_auto/v1/static/tgsgjlrzkkz2khhvpt2x"
    alt="Certified Pre-Owned Device"
    className="absolute bottom-[-125px] left-[-90px] w-[500px] z-10"
  />
</div>
</div>




      {/* Phone layout */}
      <div className="md:hidden grid grid-cols-1 gap-8 container mx-auto">
  {/* Adjusted layout with two rows for phone layout */}
<div className="md:hidden grid grid-cols-1 gap-8 container mx-auto">
  {/* First row: REGEN and Pages */}
  <div className="grid grid-cols-2 gap-4">
<div className="space-y-6">
  {/* Adding the Cloudinary image and the ADISA text */}
  <div className="space-y-6">
    <img src="https://res.cloudinary.com/dl1kjmaoq/image/upload/f_auto,q_auto/v1/static/m4gnwmpxzmss60wydlki" alt="ADISA Certified" className="w-1/2 h-auto" />
    <p className="text-gray-500 font-urbanist text-sm">ADISA Certified Device Erasure!</p>
  </div>
</div>

    <div className="space-y-6 text-left">
      <h6 className="text-lg font-bold font-urbanist mb-2">Services</h6>
      <ul className="space-y-2">
        <li><button className="block font-urbanist text-gray-700 hover:text-gray-900">Home V1</button></li>
        <li><button className="block font-urbanist text-gray-700 hover:text-gray-900">Home V2</button></li>
        <li><button className="block font-urbanist text-gray-700 hover:text-gray-900">Home V3</button></li>
        <li><button className="block font-urbanist text-gray-700 hover:text-gray-900">About</button></li>
        <li><button className="block font-urbanist text-gray-700 hover:text-gray-900">Services</button></li>
      </ul>
    </div>
  </div>

  {/* Second row: Utility Pages and Contact Us */}
  <div className="grid grid-cols-2 gap-4">
    <div className="space-y-6">
      <h6 className="text-lg font-bold font-urbanist mb-2">Legal</h6>
      <ul className="space-y-2">
        <li><button className="block font-urbanist text-gray-700 hover:text-gray-900">Start here</button></li>
        <li><button className="block font-urbanist text-gray-700 hover:text-gray-900">Style guide</button></li>
        <li><button className="block font-urbanist text-gray-700 hover:text-gray-900">404 not found</button></li>
        <li><button className="block font-urbanist text-gray-700 hover:text-gray-900">Password protected</button></li>
        <li><button className="block font-urbanist text-gray-700 hover:text-gray-900">Licenses</button></li>
      </ul>
    </div>

    <div className="space-y-6 text-left">
      <h6 className="text-lg font-bold font-urbanist mb-2">Help</h6>
                 <div
  className="flex items-center mb-2 cursor-pointer"
  onClick={() => window.open('https://api.whatsapp.com/send?phone=923111005569', '_blank')}
>
  <FaWhatsapp className="h-5 w-5 text-gray-700 mr-2" />
  <p className="text-gray-500 font-urbanist text-sm">WhatsApp Chat</p>
</div>
      <div className="flex items-center mb-2">
        <FaMap className="h-5 w-5 text-gray-700 mr-2" />
        <p className="text-gray-500 font-urbanist text-sm">297 St 10, Sector Y, DHA Phase 3, Lahore</p>
      </div>
      <div className="flex items-center mb-2">
        <FaEnvelope className="h-5 w-5 text-gray-700 mr-2" />
        <p className="text-gray-500 font-urbanist text-sm">support@regen.pk</p>
      </div>
      <div className="flex items-center mb-2">
        <FaPhone className="h-5 w-5 text-gray-700 mr-2" />
        <p className="text-gray-500 font-urbanist text-sm">(+92) 311-1005569</p>
      </div>
    </div>
  </div>
  </div>


  {/* Third row: Pink CTA with Image for phones */}
<div className="col-span-1 relative rounded-3xl">
  <div className="bg-pink-100 rounded-3xl relative text-center flex flex-col justify-start items-center h-full pb-96">
    <h4 className="text-2xl md:text-3xl font-semibold font-urbanist text-gray-800 mt-5">
      Upgrade with a Great <br /> Certified Used Device!
    </h4>
    <div className="flex flex-col items-center mt-4">
      <button className="bg-black text-white rounded-full px-8 py-3 font-semibold font-urbanist shadow-md hover:bg-gray-800 mb-5">
        Upgrade Your Device
      </button>
      <button className="text-black font-urbanist font-semibold text-lg">Browse all devices →</button>
    </div>
  </div>



    {/* Positioned image */}
    <img
      loading='lazy'
      src="https://res.cloudinary.com/dl1kjmaoq/image/upload/f_auto,q_auto/v1/static/tgsgjlrzkkz2khhvpt2x"
      alt="Certified Pre-Owned Device"
      className="absolute bottom-[-40px] left-[-30px] w-[380px] z-1"
    />
  </div>
  </div>

<div className="hidden md:block text-center mt-16">
  <p className="text-gray-500 font-urbanist text-sm">Copyright © REGEN | 2024</p>
</div>

  
    </footer>
  );
};

export default Footer;
