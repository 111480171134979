import React, { useState, useEffect } from 'react';
import axiosInstance from './baseURL';
import { useNavigate } from 'react-router-dom'; // Import useNavigate

const MacBook = () => {
  const [macBooks, setMacBooks] = useState([]);
  const navigate = useNavigate(); // Initialize useNavigate

  // Helper to preload images using <link> with fetchpriority
const preloadImage = (url) => {
  const link = document.createElement('link');
  link.rel = 'preload';
  link.as = 'image';
  link.href = url;
  link.setAttribute('fetchpriority', 'high'); // Set high priority for LCP
  document.head.appendChild(link);
};

  useEffect(() => {
    const fetchMacBooks = async () => {
      try {
        const response = await axiosInstance.get('api/macbooks');
        console.log('MacBooks:', response.data);
        setMacBooks(response.data);

        // Preload images for all MacBooks after data is fetched
        response.data.forEach((macBook) => {
          const firstColorImage = macBook.colors.length > 0 ? macBook.colors[0].image : '';
          if (firstColorImage) {
            preloadImage(firstColorImage); // Preload first image of each MacBook
          }
        });
      } catch (error) {
        console.error('Error fetching MacBooks:', error);
      }
    };

    fetchMacBooks();
  }, []);

  const handleProductClick = (id) => {
    window.scrollTo({ top: 0, behavior: 'smooth' }); // Scroll to top
    navigate(`/product/macbook/?id=${id}`); // Navigate to the product page
  };

  return (
    <div className="container mx-auto px-5 py-10 mt-12">
      <h2 className="text-4xl lg:text-5xl font-semibold text-center font-urbanist mb-12">
        Choose Your MacBook
      </h2>
      <div className="grid gap-6 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 mb-12 mx-auto max-w-7xl">
        {macBooks.map((macBook) => {
          const firstColorImage = macBook.colors.length > 0 ? macBook.colors[0].image : '';

          return (
            <div
              key={macBook.id}
              className="group block cursor-pointer"
              onClick={() => handleProductClick(macBook.id)} // Handle click to scroll and navigate
            >
              <div className="bg-gray-100 p-6 rounded-3xl shadow-lg transform transition-transform duration-300 group-hover:scale-105">
                {/* Desktop and larger screens */}
                <div className="hidden md:block">
                  <div className="text-center mb-4">
                    <img
                      src={firstColorImage}
                      alt={macBook.modelName}
                      className="h-48 w-auto mx-auto rounded-xl"
                    />
                  </div>
                  <h3 className="text-lg font-bold font-urbanist mb-2 text-center">
                    {macBook.modelName}
                  </h3>
                  <p className="text-gray-500 text-sm font-semibold font-urbanist mb-4 text-center">
                    Get up to Rs: {macBook.maxPrice.toLocaleString()}
                  </p>
                  <div className="flex items-center justify-center bg-white rounded-full shadow-md p-3 w-full">
                    <p className="font-bold font-urbanist text-sm">
                      Get exact value
                    </p>
                  </div>
                </div>

                {/* Phone screens - Row layout */}
                <div className="md:hidden flex items-center justify-between">
                  <div className="w-2/3 pr-4">
                    <h3 className="text-lg font-bold font-urbanist mb-1">
                      {macBook.modelName}
                    </h3>
                    <p className="text-gray-500 text-sm font-semibold font-urbanist mb-4">
                      Get up to Rs: {macBook.maxPrice.toLocaleString()}
                    </p>
                    <div className="flex items-center bg-white rounded-full shadow-md p-3 w-full mt-2">
                      <p className="font-bold font-urbanist text-sm">
                        Get exact value
                      </p>
                    </div>
                  </div>
                  <div className="w-1/3 flex justify-end">
                    <img
                      src={firstColorImage}
                      alt={macBook.modelName}
                      className="h-24 w-auto rounded-xl"
                    />
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default MacBook;
