import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

const Navbar = ({ onLogoClick = () => {} }) => {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const navigate = useNavigate();

  const handleMenuToggle = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };

  const handleLogoClick = () => {
    onLogoClick();
    navigate('/');
  };

  return (
    <nav className="fixed w-full bg-white shadow-md z-50">
      <div className="container mx-auto px-5 py-4 flex justify-between items-center">
        {/* Hamburger icon for mobile and tablets */}
        <button
          className="lg:hidden text-gray-700 focus:outline-none"
          onClick={handleMenuToggle}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M4 6h16M4 12h16m-7 6h7"
            />
          </svg>
        </button>

        {/* Logo */}
        <div
          onClick={handleLogoClick}
          className="absolute left-1/2 transform -translate-x-1/2 cursor-pointer"
        >
          <img
            loading='lazy'
            src="https://res.cloudinary.com/dl1kjmaoq/image/upload/f_auto,q_auto/v1/static/hlgpinitoohij1kupx7z"
            alt="Logo"
            className="h-12"
          />
        </div>

        {/* Navbar links for larger screens */}
        <div className="hidden lg:flex space-x-8 font-urbanist font-semibold text-gray-800">
          <Link to="/iphone" className="hover:text-gray-500">
            iPhone
          </Link>
          <Link to="/ipad" className="hover:text-gray-500">
            iPad
          </Link>
          <Link to="/samsung" className="hover:text-gray-500">
            Samsung
          </Link>
          <Link to="/macbook" className="hover:text-gray-500">
            MacBook
          </Link>
          <Link to="#" className="hover:text-gray-500">
            Watch
          </Link>
          <Link to="#" className="hover:text-gray-500">
            Accessories
          </Link>
        </div>

        {/* Need Help button for larger screens */}
        <div className="hidden lg:flex">
          <button className="bg-black text-white rounded-full font-semibold px-6 py-2 hover:bg-gray-800">
            Need Help?
          </button>
        </div>
      </div>

      {/* Mobile and tablet menu */}
      {isMobileMenuOpen && (
        <div className="lg:hidden bg-white shadow-md">
          <div className="container mx-auto py-2"> {/* Reduced padding */}
            <ul className="space-y-2 text-sm font-urbanist font-semibold text-gray-800"> {/* Adjusted size */}
              <li>
                <Link to="/iphone" onClick={handleMenuToggle}>
                  iPhone
                </Link>
              </li>
              <li>
                <Link to="/ipad" onClick={handleMenuToggle}>
                  iPad
                </Link>
              </li>
                <li>
                <Link to="/samsung" onClick={handleMenuToggle}>
                  Samsung
                </Link>
              </li>
              <li>
                <Link to="/macbook" onClick={handleMenuToggle}>
                  MacBook
                </Link>
              </li>
              <li>
                <Link to="#" onClick={handleMenuToggle}>
                  Watch
                </Link>
              </li>
              <li>
                <Link to="#" onClick={handleMenuToggle}>
                  Accessories
                </Link>
              </li>
              {/* No button style for Need Help */}
              <li>
                <Link to="#" onClick={handleMenuToggle} className="text-black">
                  Need Help?
                </Link>
              </li>
            </ul>
          </div>
        </div>
      )}
    </nav>
  );
};

export default Navbar;
